<template>
    <v-content class="photo">
        <SelectedShop v-if="init.branch_flg == 1" :selected_shop_name="selected_shop" :router_param="{ name: 'photo_shops', query: { feature_id: 'photo'}}"></SelectedShop>

        <div class="ma-2 photo" v-if="medias">
            <v-row dense class="">
                <v-col cols="6" v-for="(media, index) in medias" :key="index" class="">
                    <v-card class="pa-0">
                        <v-img class="" :src="media.thumb" lazy-src="@/assets/nophoto.png" @click="onClickBtn(media, index)">
                            <template v-slot:placeholder>
                              <v-row class="fill-height ma-0" align="center" justify="center">
                                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                              </v-row>
                            </template>
                        </v-img>
                    </v-card>
                </v-col>
            </v-row>
        </div>


            <v-dialog v-model="dialog" v-if="currentphoto" activator>
                <v-card tile class="noround">
                    <v-img class="" :src="currentphoto.src" lazy-src="@/assets/nophoto.png">
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                          </v-row>
                        </template>
                    </v-img>
                </v-card>
            </v-dialog>

            <div v-if="dialog && (currentphoto.caption || currentphoto.url)" transition="fade-transition" class="photoinfo">
                  {{currentphoto.caption}}
                  <div v-if="currentphoto.url" class="maincolor" @click.stop="toLink(currentphoto.url)">
                    <v-icon x-small class="maincolor">fas fa-link</v-icon> <span v-if="currentphoto.image_link_name">{{currentphoto.image_link_name}}</span><span v-else>{{currentphoto.url}}</span>
                  </div>
            </div>

            <div v-if="dialog && nextArwFlg" class="photoarrow arwnext" @click.stop="photoNext()">
              <v-icon color="#fff">fas fa-chevron-right</v-icon>
            </div>
            <div v-if="dialog && backArwFlg" class="photoarrow arwback" @click.stop="photoBack()">
              <v-icon color="#fff">fas fa-chevron-left</v-icon>
            </div>
		<!-- InfiniteLoading -->
    <infinite-loading ref="infiniteLoading"  @infinite="infiniteHandler" spinner="spiral">
			<div slot="spinner"><v-progress-circular :size="30" :width="2" class="maincolor" indeterminate></v-progress-circular></div>
			<div slot="no-more"></div>
			<div slot="no-results">登録されているPHOTOはありません</div>
		</infinite-loading>
    
    </v-content>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
import SelectedShop from '@/components/selected_shop.vue'

export default {
    components: {
        InfiniteLoading,
        SelectedShop,
    },
    data() {
        return {
            page: 1,
            result: null,
            medias: null,
            init: [],
            currentphoto: null,
            nextphoto: null,
            backphoto: null,
            photoNumber: null,
            dialog: false,
            backArwFlg: false,
            nextArwFlg: false,
            photoCount: null,
        };
    },
    created() {
        this.init = this.storageGet('*')

        var selected_shop = this.getUrlGetParam('selected_shop');

        if (selected_shop) {
            this.selected_shop = selected_shop
        } else {
            this.selected_shop = '全て'
        }
    },
    methods: {
        async infiniteHandler() {
            this.init = this.storageGet('*')

            var shop_id = this.getUrlGetParam('shop_id');
            var selected_shop = this.getUrlGetParam('selected_shop');

            if (selected_shop) {
                this.selected_shop = selected_shop
            } else {
                this.selected_shop = '全て'
            }

            var params = {
                'page': this.page
            }

            if (shop_id) {
                params['shop_id'] = shop_id
            } else {
                params['shop_id'] = this.init.sid
            }

            var reqest_params = { params: params }

            var res = await this.apiCall('/photo/list', reqest_params)

            if (this.page == 1) {
                this.$set(this, 'result', res)
                this.$set(this, 'medias', res.data)
            } else {
              if (res.data && res.data.length > 0) {
                this.medias = this.medias.concat(res.data);
              }
            }

            this.page = this.page + 1

            if (res.data && res.data.length > 0) {
                this.$refs.infiniteLoading.stateChanger.loaded()
            } else {
                this.$refs.infiniteLoading.stateChanger.complete()
            }
        },
        openGallery(index) {
            this.$refs.lightbox.showImage(index);
        },
        onClickBtn(media, index) {
          this.photoNumber = index
          this.nextphoto = this.medias[index+1]
          this.backphoto = this.medias[index-1]
          this.currentphoto = media
          this.dialog = true
          this.photoCount = this.medias.length
          this.Arwflg()
        },
        photoNext(){
          this.currentphoto = this.medias[this.photoNumber+1]
          this.photoNumber++;
          this.Arwflg()
        },
        photoBack(){
          if(this.photoNumber > 0) {
            this.currentphoto = this.medias[this.photoNumber-1]
            this.photoNumber--;
            this.Arwflg()
          }
        },
        Arwflg(){
          if(this.photoNumber == 0){
            this.backArwFlg = false
          }else{
            this.backArwFlg = true
          }
          if(this.photoNumber == (this.photoCount - 1)){
            this.nextArwFlg = false
          }else{
            this.nextArwFlg = true
          }
        },
        toLink(url) {
            var req = {
              destination: url,
              transition_type: 4,
            }
            if (!(this.screenTransition(req))) {
              this.callDialog('遷移エラー', '遷移情報が不正です。再度アクセスして下さい。', 2);
            }
        },
    }
};
</script>

<style scoped>
img.thumbnail {
    width: 100%;
    height: auto;
    cursor: pointer;
}

.photoinfo {
    position: fixed;
    color: #fff;
    background-color: rgba(0, 0, 0, .7);
    height: auto;
    min-height: 40px;
    width: 100%;
    left: 0;
    bottom: 0;
    text-align: left;
    padding: 10px;
    box-sizing: border-box;
    z-index: 500;
}
.v-card.noround:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0 !important;
}
.photoinfo div {
    display: block;
    text-align: right;
    text-decoration: underline;
}
.photoarrow {
    position: fixed;
    color: #fff;
    background-color: rgba(0, 0, 0, .7);
    z-index: 400;
    top: 45%;
    padding: 10px;
}
.photoarrow.arwnext {
  right: 0;
}
.photoarrow.arwback {
  left: 0;
}
</style>
